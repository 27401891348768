import React, { useState } from "react";
import { Link } from "gatsby";
import axios from "axios";
import styled from "styled-components";
import Button from "./Button";
import { breakPoints } from "./theme";
import SocialWidgets from "./footer/socialwidgets";
import FooterBottomWrap from "./footer/footerbottom";
import KeyFiAudit from "../docs/Keyfi_SC_Audit_Report.pdf";
import HackenLogo from "../images/icons/hacken.svg";
import { useTranslation } from "react-i18next";

const StyledButton = styled(Button)`
  padding: 8px 24px;
  color: ${(props) => props.theme.white1};
  font-weight: bold;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1.25em;
  line-height: 24px;
  border-radius: 4px;
  box-sizing: border-box;
  height: 48px;
  background: linear-gradient(256.02deg, #0ca7eb 2.19%, #005ad3 188.15%);
  border-radius: 0 4px 4px 0;

  &:hover {
    background: linear-gradient(256.02deg, #005ad3 2.19%, #0ca7eb 188.15%);
  }

  @media (max-width: 768px) {
    padding: 8px;
  }

  @media (max-width: 374px) {
    border-radius: 0;
    width: 100%;
  }
`;
const Wrapper = styled.div`
  position: relative;

  background: ${(props) =>
    props.isPro
      ? `linear-gradient(
    108.52deg,
    #111417 12.55%,
    #17212d 50.76%,
    #111417 88.78%
  )`
      : `#ececec`};
  width: 100%;

  .footer-border {
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 65px;
  }
`;
const InnerWrapper = styled.div`
  width: 65%;
  margin: 0 auto;
  padding: 4.5em 0 2em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  * {
    color: ${(props) => props.isPro && `${props.theme.proText} !important`};
  }
  h3 {
    color: ${(props) => props.isPro && `white !important`};
  }
  @media (max-width: ${breakPoints.md}px) {
    width: calc(100% - 32px);
  }
`;

const StyledTitle = styled.h3`
  text-align: center;
  color: ${(props) => props.theme.grey3};
  font-style: normal;
  font-size: 2em;
  line-height: 48px;
  margin: 0px auto 0.25em auto;
`;

const StyledText = styled.div`
  width: 95%;
  text-align: center;
  max-width: 766px;
  color: ${(props) => props.theme.grey3};
  font-family: "Telex", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.25em;
  line-height: 1.25;
  margin-bottom: 1rem;
`;

const InputWrapper = styled.div`
  background: ${(props) => props.theme.btnPrimaryColor};
  border-radius: 6px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.04);
  height: 48px;
  width: 70%;
  max-width: 620px;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    color: ${(props) => props.theme.grey3};
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    padding-left: 20px;
    border-radius: 4px;
    &::placeholder {
      font-family: "Montserrat";
      color: #99a7c2;
      font-weight: 300;
      font-size: 16px;
    }
  }

  @media (max-width: 480px) {
    width: 100%;
  }

  @media (max-width: 374px) {
    flex-direction: column;
    height: 80px;
  }
`;

const AuditWrapper = styled.div`
  margin: 4em 0 2em;
`;

const CopyRights = styled.div`
  font-family: "Telex", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.lightBlue3};
  margin-top: 40px;

  a {
    color: ${(props) => props.theme.lightBlue3};
  }

  a:hover {
    color: ${(props) => props.theme.grey3};
  }
`;

const SuccessSubscribe = styled.div`
  margin-top: 1rem;
  color: ${(props) => (props.false ? "red" : "black")};
`;

export default function Footer({ isPro }) {
  const [email, setEmail] = useState("");
  const [added, setAdded] = useState(false);
  const [error, setError] = useState(false);

  const { t } = useTranslation();
  const addSubscriber = async () => {
    setError(false);
    setAdded(false);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = {
        email,
      };

      await axios.post(
        `https://dev.api.keyfi.com/users/subscribe`,
        body,
        config
      );

      setAdded(true);
    } catch (err) {
      setError(true);
    }
  };

  return (
    <Wrapper isPro={isPro}>
      <InnerWrapper isPro={isPro}>
        <StyledTitle isPro={isPro}>
          {t("homepage.JoinOurCommunity.title")}
        </StyledTitle>
        <StyledText> {t("homepage.JoinOurCommunity.description")}</StyledText>

        <SocialWidgets isPro={isPro} />
        {!isPro && (
          <>
            <StyledTitle> {t("homepage.stayUpToDate.title")}</StyledTitle>
            <StyledText>{t("homepage.stayUpToDate.description")}</StyledText>
            <InputWrapper>
              <input
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t("addition.yourEmailAddress")}
              ></input>
              <StyledButton onClick={addSubscriber}>
                {t("addition.subscribe")}
              </StyledButton>
            </InputWrapper>
            {added && (
              <SuccessSubscribe>{t("addition.subscribeOK")}</SuccessSubscribe>
            )}
            {error && (
              <SuccessSubscribe false>
                {t("addition.subscribeFAIL")}
              </SuccessSubscribe>
            )}
          </>
        )}

        <AuditWrapper>
          <StyledTitle>{t("homepage.aduitedBy")}</StyledTitle>
          <StyledText>
            <a href={KeyFiAudit} target="_blank" rel="noopener noreferrer">
              <img src={HackenLogo} alt="Audited by Hacked"></img>
            </a>
          </StyledText>
        </AuditWrapper>
        <FooterBottomWrap isPro={isPro} />
        <CopyRights>
          <Link to="/privacy">Privacy</Link>
          <Link style={{ marginLeft: "10px" }} to="/terms">
            Terms
          </Link>
          <Link style={{ marginLeft: "10px" }} to="/toc">
            TOC
          </Link>

          <span style={{ paddingLeft: 10, paddingRight: 10 }}> | </span>
          <span>© 2021 by KeyFi Issuer LLC</span>
        </CopyRights>
      </InnerWrapper>
      <span className="footer-border"></span>
    </Wrapper>
  );
}
